import React, { Fragment } from "react";
import { Divider, Box } from "@eriksdigital/atomic-ui/components";
import { Step, StepNumber, StepTick } from "./styles";
import { ReactComponent as Arrow } from "../../assets/ic_arrow.svg";
import { ReactComponent as Tick } from "../../assets/ic_check.svg";
import { FormattedMessage } from "react-intl";

const Navigation = ({
  currentStep,
  goToStep,
  stepOneComplete,
  stepTwoComplete
}: any) => {
  const getStepCompletionState = (stepNumber: number) => {
    if (stepNumber === currentStep) {
      return "current";
    }

    if (stepNumber < currentStep) {
      return "completed";
    }

    return "default";
  };

  const step1State = getStepCompletionState(1);
  const step2State = getStepCompletionState(2);
  const step3State = getStepCompletionState(3);

  return (
    <Fragment>
      <Box
        padding="sp16"
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Step>
          <StepNumber
            data-testid="stepOneNumber"
            clickable
            variant={step1State}
            onClick={() => goToStep(1)}
          >
            1
            {stepOneComplete && (
              <StepTick data-testid="stepOneComplete">
                <Tick viewBox="-2 -5 20 20" fill="white" />
              </StepTick>
            )}
          </StepNumber>
          <h4 data-testid="step-title-1">
            <FormattedMessage
              id="step-title.motor-selection"
              defaultMessage="Motor selection"
              description="label for motor selection step"
            />
          </h4>
        </Step>
        <Step>
          <Arrow fill="#d2d6de" width="25px" />
        </Step>
        <Step>
          <StepNumber
            data-testid="stepTwoNumber"
            clickable={stepOneComplete}
            variant={step2State}
            onClick={() => {
              if (stepOneComplete) {
                goToStep(2);
              }
              return;
            }}
          >
            2
            {stepTwoComplete && (
              <StepTick data-testid="stepTwoComplete">
                <Tick viewBox="-2 -5 20 20" fill="white" />
              </StepTick>
            )}
          </StepNumber>
          <h4 data-testid="step-title-2">
            <FormattedMessage
              id="step-title.usage"
              defaultMessage="Usage"
              description="label for usage step"
            />
          </h4>
        </Step>
        <Step>
          <Arrow fill="#d2d6de" width="25px" />
        </Step>
        <Step>
          <StepNumber
            data-testid="stepThreeNumber"
            clickable={stepOneComplete && stepTwoComplete}
            variant={step3State}
            onClick={() => {
              if (stepOneComplete && stepTwoComplete) {
                goToStep(3);
              }
              return;
            }}
          >
            3
          </StepNumber>
          <h4 data-testid="step-title-3">
            <FormattedMessage
              id="step-title.results"
              defaultMessage="Results"
              description="label for show results"
            />
          </h4>
        </Step>
      </Box>
      <Divider />
    </Fragment>
  );
};

export default Navigation;

interface LanguageType {
  [key: string]: number;
}

const languages: LanguageType = {
  nl: 31,
  en: -1,
  fr: -2,
  de: -3
};

export const languageToCode = (locale: string) => {
  return languages[locale];
};

export const fetchTranslations = ({ language = "en" }) => {
  if (process.env.REACT_APP_USE_MOCK_TRANSLATIONS === "yes") {
    return import(`../mocks/locales/${language}`).then(module => {
      return module.default;
    });
  } else {
    return import(`../../locales/${language}`).then(module => {
      return module.default;
    });
  }
};

export type PimLocalesDropdownType = {
  [key: string]: { value: string; label: string };
};

export const pimLocalesDropdown: PimLocalesDropdownType = {
  "31": {
    value: "nl",
    label: "Nederlands"
  },
  "-1": {
    value: "en",
    label: "English"
  },
  "-2": {
    value: "fr",
    label: "Français"
  },
  "-3": {
    value: "de",
    label: "Deutsch"
  }
};

import styled from "styled-components";

export const StyledSelect = styled.select`
  border: 1px solid #d2d0d0;
  padding: 0.5rem;
  padding-right: 25px;
  min-width: 100%;
  border-radius: 2px;
  background: transparent;
  background-image: none;
  appearance: none;

  &:focus {
    border: 1px solid rgba(0, 92, 169, 1);
  }
`;

export const StyledSelectLabel = styled.label`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 0px;
  max-width: 300px;
  width: 100%;

  div + label {
    margin-top: 10px;
  }
`;

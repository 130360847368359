import { ResourcesMap } from "@eriksdigital/raffaella";
// import { statuses } from "./containers/ORingSelector/Results/StockInformation";

// export type StockLevel = {
//   available: keyof statuses;
//   deliveryDate: string;
//   number: string;
// };

export type AvailableLanguages = {
  countryCode: string;
};

// const createEnvString: () => string = () => {
//   switch (process.env.REACT_APP_ENVIRONMENT) {
//     case "prod": {
//       return "";
//     }
//     case "dev": {
//       return "test";
//     }
//     default: {
//       return process.env.REACT_APP_ENVIRONMENT || "";
//     }
//   }
// };

// const createDefaultHeader: () => { [k: string]: string } = () => {
//   const env = createEnvString();
//   return {
//     Host: `api.${env}.eriksdigital.com`,
//   };
// };

const config: ResourcesMap = {
  salesOrganization: {
    apiEndpoint: process.env.OPEN_FAAS_URL
      ? `${process.env.OPEN_FAAS_URL}/sales-organizations`
      : "https://fa-ed-eu-tst-gb-cicd.azurewebsites.net/api/sales-organizations",
    method: "GET",
  },
  items: {
    apiEndpoint: process.env.TCO_SELECTOR_API
      ? `${process.env.TCO_SELECTOR_API}/v3/items`
      : "https://api.test.eriksdigital.com/guided-selling-motor-calculator/api/v3/items",
    method: "GET",
  },
  motorUsage: {
    apiEndpoint: process.env.TCO_SELECTOR_API
      ? `${process.env.TCO_SELECTOR_API}/usage`
      : "https://api.test.eriksdigital.com/guided-selling-motor-calculator/api/usage",
    method: "GET",
    transformDTO: (DTO) => DTO.attributes,
  },
  motors: {
    apiEndpoint: process.env.TCO_SELECTOR_API
      ? `${process.env.TCO_SELECTOR_API}/v3/motors`
      : "https://api.test.eriksdigital.com/guided-selling-motor-calculator/api/v3/motors",
    method: "GET",
    transformDTO: (DTO) => DTO.motors,
  },
  attributes: {
    apiEndpoint: process.env.TCO_SELECTOR_API
      ? `${process.env.TCO_SELECTOR_API}/v3/attributes`
      : "https://api.test.eriksdigital.com/guided-selling-motor-calculator/api/v3/attributes",
    method: "GET",
  },
  stockLevels: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/v2/stock`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/v2/stock",
    method: "POST",
    transformDTO: (data) => data.items,
  },
  user: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cookies/isLoggedIn`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cookies/isLoggedIn",
    method: "GET",
  },
  addToCart: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cart/addToCart`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cart/addToCart",
    method: "POST",
  },
  shoppingCart: {
    apiEndpoint: process.env.WEBSHOP_INTEGRATION_API
      ? `${process.env.WEBSHOP_INTEGRATION_API}/cart`
      : "https://api.test.eriksdigital.com/guided-selling-webshop-integration/api/cart",
    method: "GET",
  },
  availableLanguages: {
    apiEndpoint: ":storeUrl/wcs/resources/store/:storeId/available-languages",
    method: "GET",
    transformDTO: (DTO) => ({ data: Object.keys(DTO) }),
  },
  price: {
    apiEndpoint: process.env.ERPAL_INTEGRATION_API
      ? `${process.env.ERPAL_INTEGRATION_API}/prices/cookie`
      : "https://api.test.eriksdigital.com/guided-selling-erpal-integration/api/prices/cookie",
    method: "GET",
    transformDTO: (DTO: any) => ({ data: DTO.items }),
  },
};

export { config };

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HamBar = styled.span<{ open: boolean }>`
  width: 1.5rem;
  height: 0.125rem;
  background-color: ${props => props.color};
  display: block;
  margin: 0.25rem auto;
  transition: all 0.3s ease-in-out;

  &:nth-child(2) {
    opacity: ${props => (props.open ? 0 : 1)};
  }

  &:nth-child(1) {
    transform: ${props =>
      props.open ? "translate(0px, 7px) rotate(45deg)" : "none"};
  }

  &:nth-child(3) {
    transform: ${props =>
      props.open ? "translate(0px, -5px) rotate(-45deg)" : "none"};
  }
`;

const MenuButton = styled.button`
  cursor: pointer;
  border: none;
  /* uncommon spacing 40x40px*/
  padding: 0.5625rem 0.5rem;
  background: transparent;
  outline: none;
`;

interface HamburgerProps {
  id?: string;
  onClick: () => void;
  open: boolean;
  color: string;
}

const Hamburger = (props: HamburgerProps) => {
  const { id, onClick, open, color } = props;
  return (
    <MenuButton id={id} data-testid="menu-button" onClick={onClick}>
      <HamBar open={open} color={color} />
      <HamBar open={open} color={color} />
      <HamBar open={open} color={color} />
    </MenuButton>
  );
};

Hamburger.defaultProps = {
  onClick: null,
  color: "#0163af",
  open: false
};

Hamburger.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  open: PropTypes.bool
};

Hamburger.displayName = "Hamburger";

export default Hamburger;

import styled from "styled-components";

export const HeaderWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: 64px;
  border-bottom: 1px solid #c8ccd6;
`;

export const HeaderInnerWrapper = styled.div`
  width: 100%;
  max-width: 75em;
  margin: 0 auto;
`;

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.9375rem;
`;

export const LogoWrapper = styled.a`
  width: 100px;
  height: 64px;
  display: flex;
  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
  svg {
    width: 100%;
  }
`;

export const Logo = styled.div``;

export const MenuPanelContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LanguagePanel =
  styled.div<{ menuOpen: boolean}>
  `
  position: relative;
  width: 200px;
  top: 10px;
  z-index: 1;
  height: auto;
  transform: scaleY(${props => (props.menuOpen ? "1" : "0")});
  overflow: hidden;
  background-color: white;
  padding: 20px;
  border: ${props => (props.menuOpen ? "1px solid #dedede" : "0")};
  opacity: ${props => (props.menuOpen ? "1" : "0")};
  transition: transform 0.1s, opacity 0.3s;
`;

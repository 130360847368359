import React, {
  Suspense,
  Fragment,
  lazy,
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect
} from "react";
import { LanguageContext, Box } from "@eriksdigital/atomic-ui/components";

import Intro from "./components/Intro";
import Navigation from "../../components/Navigation";

import { pimLocales, stepOneAttributes } from "./constants";
import { MainWrapper } from "./styles";
import { Loading } from "../../components/Loading";
import { prepareAttributeQueryParams } from "../../utils/prepareQueryParams";
import { useBasenamePath } from "../../utils/useBasenamePath";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from "react-router-dom";
import { GTMContext } from "@eriksdigital/gs-ga4";
import { useRaffaella } from "@eriksdigital/raffaella";

const StepOne = lazy(() => import("./components/StepOne"));
const StepTwo = lazy(() => import("./components/StepTwo"));
const StepThree = lazy(() => import("./components/StepThree"));

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "UPDATE":
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          [action.payload.name]: action.payload.value
        }
      };

    default:
      return state;
  }
};

const initialState = {
  attributes: {
    power: "",
    speed: "",
    ratedVoltage: "",
    mounting: ""
  },
  usage: {
    efficiencyBand: "IE1",
    motorLifeSpan: "12",
    numberOfRewinds: "none",
    load: "100",
    rewind: "true",
    repairCost: "",
    energyTariff: "",
    daysAYear: "365",
    hoursPerDay: "22",
    repair: "true"
  }
};

export const TCOCalculator = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
  const [selectedAttributes, dispatch] = useReducer(reducer, initialState);
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const basenamePath = useBasenamePath();
  const [attributes, makeResourceCallAttributes] = useRaffaella("attributes");
  const [motorUsage, makeResourceCallMotorUsage] = useRaffaella("motorUsage");
  const [user] = useRaffaella("user");
  const history = useHistory();
  const {
    isReadyToSendEvents,
    sendProcessStartEvent,
    sendPageViewEvent
  } = useContext(
    //@ts-ignore
    GTMContext
  );
  const location = useLocation();
  const {
    userType,
    userId,
    userSegment,
    loggedIn,
    loginLoading,
    customerNumber,
    organizationName,
    userSalesChannel
  } = user.data ?? {};

  useEffect(() => {
    if (
      currentStep === 1 &&
      location.pathname.includes("motor-selection") &&
      !user.isLoading &&
      !!user.data
    ) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber || "",
        organizationName: organizationName || "",
        userSalesChannel,
        userType,
        userId: (!!userId && userId.toString()) || "",
        userSegment,
        loggedIn
      });
      sendProcessStartEvent({ processStepName: "1_chooseproductdetails" });
    }
  }, [
    currentStep,
    sendProcessStartEvent,
    location,
    isReadyToSendEvents,
    sendPageViewEvent,
    erpSystem,
    loginLoading,
    userType,
    userId,
    userSegment,
    loggedIn,
    customerNumber,
    organizationName,
    userSalesChannel,
    user.isLoading,
    user.data
  ]);

  useEffect(() => {
    makeResourceCallAttributes &&
      makeResourceCallAttributes({
        apiKey: "attributes",
        reqData: {
          ...prepareAttributeQueryParams(selectedAttributes),
          locale: pimLocales[language],
          erpSystem: erpSystem.toUpperCase()
        }
      });
  }, [erpSystem, language, makeResourceCallAttributes, selectedAttributes]);

  useEffect(() => {
    makeResourceCallMotorUsage &&
      makeResourceCallMotorUsage({
        apiKey: "motorUsage",
        reqData: {
          locale: pimLocales[language],
          erpSystem: erpSystem.toUpperCase()
        }
      });
  }, [erpSystem, language, makeResourceCallMotorUsage]);

  const updateSelectedAttribute = useCallback(
    (key: number, name: string, value: any) => {
      dispatch({ type: "UPDATE", payload: { key, name, value } });
    },
    []
  );

  const next = () => {
    setCurrentStep(c => c + 1);
    setStepTwoCompleted(currentStep === 2);
  };
  const prev = () => {
    setCurrentStep(c => c - 1);
  };

  const goToStep = (stepNumber: number) => {
    setCurrentStep(stepNumber);
  };

  const stepOneComplete = stepOneAttributes.every(attr => {
    return !!selectedAttributes.attributes[attr];
  });
  const stepTwoComplete = stepTwoCompleted;

  if (!!attributes.error || !!motorUsage.error) return <span>Error...</span>;
  return (
    <Switch>
      <Route exact path={basenamePath}>
        {attributes.isLoading || motorUsage.isLoading ? <Loading /> : <Intro />}
      </Route>
      <Route path={basenamePath + "/motor-selection"}>
        <Fragment>
          <MainWrapper>
            <Navigation
              currentStep={currentStep}
              goToStep={goToStep}
              stepOneComplete={stepOneComplete}
              stepTwoComplete={stepTwoComplete}
            />

            <Box padding="0 0.9375rem">
              {currentStep === 1 && (
                <Suspense fallback={<Loading />}>
                  {attributes.isLoading ? (
                    <Loading />
                  ) : (
                    !!attributes.data && (
                      <StepOne
                        prev={() => history.push(basenamePath)}
                        next={next}
                        attributes={attributes?.data.attributes}
                        updateSelectedAttribute={updateSelectedAttribute}
                        selectedAttributes={selectedAttributes.attributes}
                        stepOneAttributes={stepOneAttributes}
                        stepOneComplete={stepOneComplete}
                      />
                    )
                  )}
                </Suspense>
              )}

              {currentStep === 2 && (
                <Suspense fallback={<Loading />}>
                  {motorUsage.isLoading ? (
                    <Loading />
                  ) : (
                    !!motorUsage.data && (
                      <StepTwo
                        next={next}
                        prev={prev}
                        usage={motorUsage.data.attributes}
                        updateSelectedAttribute={updateSelectedAttribute}
                        selectedUsage={selectedAttributes.usage}
                        stepTwoComplete={stepTwoComplete}
                      />
                    )
                  )}
                </Suspense>
              )}

              {currentStep === 3 && (
                <Suspense fallback={<Loading />}>
                  <StepThree
                    prev={prev}
                    selectedAttributes={selectedAttributes.attributes}
                    selectedUsage={selectedAttributes.usage}
                  />
                </Suspense>
              )}
            </Box>
          </MainWrapper>
        </Fragment>
      </Route>
      <Redirect from="*" to={basenamePath} />
    </Switch>
  );
};

export default TCOCalculator;

import React, { useCallback, useContext, useEffect } from "react";
import { Icon, LanguageContext } from "@eriksdigital/atomic-ui/components";
import {
  LoadingIcon,
  CartIcon
} from "@eriksdigital/atomic-ui/components/Icons";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { IconWrapper, ItemCountCircle } from "./style";
import { constructShoppingUrl } from "../../utils/shopUtils";
import { useRaffaella } from "@eriksdigital/raffaella";
import { languageToCode } from "../../utils/languageUtils";

export const ShoppingCart = () => {
  const { erpSystem, language } = useContext<any>(LanguageContext);
  const [shoppingCart, makeResourceCallShoppingCart] = useRaffaella(
    "shoppingCart"
  );
  const [addToCart] = useRaffaella("addToCart");
  const [salesOrganization] = useRaffaella("salesOrganization");
  const x_totalOrderItemCount = shoppingCart?.data?.x_totalOrderItemCount ?? 0;

  const fetchShoppingCart = useCallback(() => {
    makeResourceCallShoppingCart &&
      makeResourceCallShoppingCart({
        apiKey: "shoppingCart",
        reqData: {
          locale: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_MOT"
        }
      });
  }, [erpSystem, language, makeResourceCallShoppingCart]);

  useEffect(() => {
    if (!!addToCart.data && addToCart.data.responseUrl) {
      fetchShoppingCart();
    }
  }, [addToCart.data, fetchShoppingCart]);

  useEffect(() => {
    fetchShoppingCart();
  }, [fetchShoppingCart]);

  return (
    <div data-testid="tco-cart" id={"navbar-cart-icon"}>
      <a
        href={
          !!salesOrganization.data &&
          language &&
          erpSystem &&
          constructShoppingUrl(salesOrganization.data, language, erpSystem)
        }
        target="_blank"
        rel="noopener noreferrer"
        data-testid="navbar-go-to-cart"
      >
        <IconWrapper>
          <ItemCountCircle isLargeAmount={x_totalOrderItemCount > 99}>
            {shoppingCart.isLoading ? (
              <Icon as={LoadingIcon} size="sz8" color={colors.default.blueD} />
            ) : (
              x_totalOrderItemCount
            )}
          </ItemCountCircle>
          <span id="cart-itemcount" style={{ display: "none" }}>
            {x_totalOrderItemCount}
          </span>
          <Icon
            as={CartIcon}
            fill={colors.heading.primary}
            height={30}
            width={30}
            viewBox="0 0 35 35"
          />
        </IconWrapper>
      </a>
    </div>
  );
};

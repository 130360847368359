import styled from "styled-components";
import { ReactSelect, Box } from "@eriksdigital/atomic-ui/components";
import { colors } from "@eriksdigital/atomic-ui/styles";
import { ReactComponent as unstyledTick } from "../../../assets/ic_check.svg";
// import { Panel } from "../../../components/Panel/style";

export const StepHeaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
`;

export const StepHeaderText = styled.div`
  text-align: center;

  p {
    margin-bottom: 30px;
  }
`;

export const StepInputsContainer = styled.div<any>`
  display: flex;
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : "row"};
  flex: 1 0 50%;
  justify-content: center;
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
`;

export const UList = styled.ul`
  margin-top: 10px;
`;

export const ListI = styled.li`
  margin: 5px 0;
`;

export const Tick = styled(unstyledTick)`
  fill: ${colors.default.green};
  margin-right: 8px;
`;

export const ContactMessage = styled.p`
  margin: 60px auto 20px;
`;

export const Image = styled.img`
  height: 140px;
  margin: 32px;
  width: 140px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  max-width: 320px;

  h4 {
    margin-bottom: 10px;
  }

  p + p {
    color: ${colors.default.darkGray};
    opacity: 0.5;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Motor = styled.a`
  display: flex;
  margin: 0 auto;
  text-decoration: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopInfo = styled.div`
  padding-top: 20px;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;
`;

export const MessageSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CoupledField = styled.div`
  margin-top: 6px;
`;

export const Dropdown = styled(ReactSelect)`
  width: 300px;
`;

export const PanelContainer = styled(Box)`
  padding: 10px 0;
`;

export const LabelStyled = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  font-size: 0.875rem;
  margin-bottom: 0.08rem;
`;

StepHeaderContainer.displayName = "StepHeaderContainer";
StepInputsContainer.displayName = "StepInputsContainer";

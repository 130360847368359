import React, { Fragment } from "react";
import { LanguageContext, Box } from "@eriksdigital/atomic-ui/components";
import { defaultTheme } from "@eriksdigital/atomic-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";

import Header from "./components/Header";
import TCOCalculator from "./containers/TCOCalculator";

import { ThemeProvider } from "styled-components";
import { isLanguageLoaded } from "./components/Translation";
import { TranslationProvider } from "./providers/TranslationProvider";
import { ResourcesProvider } from "@eriksdigital/raffaella";
import { config } from "./resourcesConfig";
import apiPath from "./utils/apiPath";

const App = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <TranslationProvider>
        <ResourcesProvider config={config} apiPath={apiPath()}>
          <LanguageContext.Consumer>
            {({ strings }: any) =>
              isLanguageLoaded(strings) && (
                <Router>
                  <Fragment>
                    <Header />
                    <Box
                      display="flex"
                      alignContent="center"
                      justifyContent="center"
                    >
                      <TCOCalculator />
                    </Box>
                  </Fragment>
                </Router>
              )
            }
          </LanguageContext.Consumer>
        </ResourcesProvider>
      </TranslationProvider>
    </ThemeProvider>
  );
};

export default App;

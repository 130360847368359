import React, { useContext, useEffect, useState } from "react";
import {
  H1,
  H2,
  P,
  Button,
  Modal,
  Box,
  LanguageContext
} from "@eriksdigital/atomic-ui/components";
import { useHistory } from "react-router-dom";
import { GTMContext } from "@eriksdigital/gs-ga4";
import Divider from "../../../components/Divider";
import { UList, ListI, Tick } from "./style";
import { useBasenamePath } from "../../../utils/useBasenamePath";
import { FormattedMessage } from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";
import { MainWrapperWide } from "../styles";

const Intro = () => {
  const { erpSystem } = useContext<any>(LanguageContext);

  const [user] = useRaffaella("user");

  const [showPopup, setPopupVisibility] = useState(false);
  const history = useHistory();
  const basenamePath = useBasenamePath();

  const { isReadyToSendEvents, sendPageViewEvent } = useContext<any>(
    //@ts-ignore
    GTMContext
  );
  const hasEnterWithoutLogin = ["nl", "be", "ch"].includes(erpSystem);

  const {
    userType,
    userId,
    userSegment,
    loginLoading,
    loggedIn,
    isGuestUser,
    loginUrl,
    customerNumber,
    organizationName,
    userSalesChannel
  } = user?.data ?? {};

  useEffect(() => {
    if (sendPageViewEvent && user.data !== null && !user.isLoading) {
      sendPageViewEvent({
        pageUrl: window.location.href,
        pagePath: window.location.pathname,
        pageHostname: window.location.hostname,
        organizationId: customerNumber || "",
        organizationName: organizationName || "",
        userSalesChannel,
        userType,
        userId: (!!userId && userId.toString()) || "",
        userSegment,
        loggedIn
      });
    }
  }, [
    isReadyToSendEvents,
    sendPageViewEvent,
    erpSystem,
    loginLoading,
    userType,
    userId,
    userSegment,
    loggedIn,
    customerNumber,
    organizationName,
    userSalesChannel,
    user.isLoading,
    user
  ]);

  const openPopup = () => {
    setPopupVisibility(true);
  };

  const closePopup = () => {
    setPopupVisibility(false);
  };

  const next = () => {
    // isReadyToSendEvents && sendProcessStartEvent({ processStepName: '1_chooseproductdetails'});
    history.push(basenamePath + "/motor-selection");
  };

  const handleNext = () => {
    if (loggedIn && !isGuestUser) {
      next();
    } else {
      openPopup();
    }
  };

  return (
    <MainWrapperWide>
      <Box direction="column" padding="0 0.9375rem">
        <Box paddingY="sp16">
          <H1>
            <FormattedMessage
              id="tco-calculator.title"
              defaultMessage="ERIKS MOTORS - TCO Calculator"
              description="TCO Calculator title text"
            />
          </H1>
        </Box>

        <Divider />

        <Box paddingY="sp16">
          <H2>
            <FormattedMessage
              id="tco-calculator.subheading"
              defaultMessage="Replace, repair or upgrade your motor?"
              description="subheading text"
            />
          </H2>
        </Box>
        <P>
          <FormattedMessage
            id="tco-calculator.description-text"
            defaultMessage="With this TCO Calculator you can compare your current motor to motors with different energy efficiency levels. When in doubt about replacing, repairing or upgrading your current motor, the calculator helps you to make a comparison based on:"
            description="description intro text"
          />
        </P>

        <UList>
          <ListI>
            <Tick />
            <FormattedMessage
              id="tco-calculator.benefit-0"
              defaultMessage="Costs & Savings"
              description="Cost and Savings benefit label text"
            />
          </ListI>
          <ListI>
            <Tick />
            <FormattedMessage
              id="tco-calculator.benefit-1"
              defaultMessage="Payback time"
              description="Payback time benefit label text"
            />
          </ListI>
          <ListI>
            <Tick />
            <FormattedMessage
              id="tco-calculator.benefit-2"
              defaultMessage="CO2 footprint"
              description="CO2 footprint benefit label text"
            />
          </ListI>
        </UList>

        <Box paddingY="sp16">
          <Button
            data-testid="intro-next"
            id="start-calculator"
            stretch="auto"
            onClick={handleNext}
          >
            <FormattedMessage
              id="tco-calculator.begin-CTA"
              defaultMessage="Start TCO Calculator"
              description="start calculator button label"
            />
          </Button>
        </Box>
        {showPopup && (
          <Modal onClose={closePopup} withOutsideClose showCloseIcon={false}>
            <Box paddingY="sp16">
              <H1 data-testid="not-logged-in-title">
                <FormattedMessage
                  id="intro.not-logged-in"
                  defaultMessage="You are not logged in"
                  description="not logged in label modal"
                />
              </H1>
            </Box>
            <Divider />
            <Box paddingY="sp16">
              {" "}
              <P>
                {/*TODO temporary solution until UK will get open prices*/}
                {hasEnterWithoutLogin ? (
                  <FormattedMessage
                    id="intro.log-in-explanation"
                    defaultMessage="When you are not logged in on the ERIKS webshop, the TCO motor calculator will calculate your options with a generic price. In addition, you will not be able to directly add your chosen item to your cart."
                    description="label of logged in explanation modal"
                  />
                ) : (
                  <FormattedMessage
                    id="intro.log-in-explanation-no-open-prices"
                    defaultMessage="If you are not logged in to the ERIKS webshop, the TCO motor calculator cannot calculate your energy saving or add your chosen item to your cart."
                    description="label of logged in explanation modal for closed prices"
                  />
                )}
              </P>
            </Box>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="start"
              paddingY="sp16"
            >
              <Box marginRight="sp16">
                <Button
                  as="a"
                  href={loginUrl || "https://shop.eriks.nl"}
                  id="to-the-shop-we-go"
                  stretch="auto"
                >
                  <FormattedMessage
                    id="intro.take-to-login"
                    defaultMessage="Login for customer specific pricing"
                    description="label of Login button"
                  />
                </Button>
              </Box>
              {hasEnterWithoutLogin && (
                <Button
                  id="ok-next"
                  onClick={next}
                  variant="secondary"
                  stretch="auto"
                >
                  <FormattedMessage
                    id="intro.take-to-tool"
                    defaultMessage="I will proceed without login"
                    description="continue without login button label"
                  />
                </Button>
              )}
            </Box>
          </Modal>
        )}
      </Box>
    </MainWrapperWide>
  );
};

export default Intro;

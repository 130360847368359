export const types = {
  REQUEST_FETCH_ATTRIBUTES: "REQUEST_FETCH_ATTRIBUTES",
  FETCH_ATTRIBUTES_SUCCEEDED: "FETCH_ATTRIBUTES_SUCCEEDED",
  FETCH_ATTRIBUTES_ERROR: "FETCH_ATTRIBUTES_ERROR",
  REQUEST_FETCH_USAGE: "REQUEST_FETCH_USAGE",
  FETCH_USAGE_SUCCEEDED: "FETCH_USAGE_SUCCEEDED",
  FETCH_USAGE_ERROR: "FETCH_USAGE_ERROR",
  START_SUBMITTING_MOTOR_DETAILS: "START_SUBMITTING_MOTOR_DETAILS",
  ERROR_SUBMITTING_MOTOR_DETAILS: "ERROR_SUBMITTING_MOTOR_DETAILS",
  MOTOR_DETAIL_SUBMISSION_SUCCESS: "MOTOR_DETAIL_SUBMISSION_SUCCESS",
  SELECT_MOTOR: "SELECT_MOTOR",
  SET_MOTOR_RESULTS: "SET_MOTOR_RESULTS",
  SELECT_ATTRIBUTE: "SELECT_ATTRIBUTE",
  ADD_TO_CART_INIT: "ADD_TO_CART_INIT",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_ERROR: "ADD_TO_CART_ERROR"
};

type pimLocalesType = {
  [key: string]: number;
};

export const pimLocales: pimLocalesType = {
  en: -1,
  fr: -2,
  de: -3,
  nl: 31
};

export const pimLocalesDropdown = {
  "31": {
    value: "nl",
    label: "Nederlands"
  },
  "-1": {
    value: "en",
    label: "English"
  },
  "-2": {
    value: "fr",
    label: "Français"
  },
  "-3": {
    value: "de",
    label: "Deutsch"
  }
};

export const stepOneAttributes = ["power", "mounting", "speed", "ratedVoltage"];

export const stepTwoSelectAttributes = [
  "efficiencyBand",
  "motorLifeSpan",
  "annualOperationHours",
  "numberOfRewinds"
];

import styled from "styled-components";

const Divider = styled.hr`
  display: block;
  height: auto;
  margin: 0;
  border-bottom: 0;
  border-left: 0;
  border-top: 1px solid;
  border-right: 1px solid;
  border-color: #c8ccd6;
  border-width: 1px;
`;

Divider.displayName = "Divider";

export default Divider;

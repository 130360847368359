import styled from "styled-components";
import { colors } from "@eriksdigital/atomic-ui/styles";

type StepType = {
  default: string;
  completed: string;
  current: string;
};

const background: StepType = {
  default: "#fff",
  completed: "#d2d6de",
  current: colors.default.blueD
};

const number: StepType = {
  default: "#d2d6de",
  completed: "#fff",
  current: "#fff"
};

const text: StepType = {
  default: "#686868",
  completed: "#686868",
  current: colors.default.blueD
};

const borderColor: StepType = {
  default: "#d2d6de",
  completed: "#d2d6de",
  current: colors.default.blueD
};

export const Step = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 400;
    margin-top: 10px;
  }
`;

export const StepNumber = styled.div<{ variant: string; clickable: boolean }>`
  background-color: ${props => background[props.variant as keyof StepType]};
  border-radius: 25px;
  border: 1px solid ${props => borderColor[props.variant as keyof StepType]};
  color: ${props => number[props.variant as keyof StepType]};
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 42px;
  position: relative;
  cursor: ${props => (props.clickable ? "pointer" : "default")};

  + h4 {
    color: ${props => text[props.variant as keyof StepType]};
  }
`;
export const StepTick = styled.span`
  position:absolute;
  border 1px solid ${colors.default.green};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  background-color: ${colors.default.green};
  color: white;
  line-height: 1.25rem;
  margin-right: 5px;
  top:-8px;
`;

Step.displayName = "Step";
StepNumber.displayName = "StepNumber";

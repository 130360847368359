import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext, Logo } from "@eriksdigital/atomic-ui/components";
import { useHistory } from "react-router-dom";
import { StyledSelect } from "../Select";
import Hamburger from "../Hamburger";
import { LogInStatus } from "../LogInStatus";
import { ShoppingCart } from "../ShoppingCart";
import {
  HeaderWrapper,
  HeaderInnerWrapper,
  HeaderInner,
  LogoWrapper,
  MenuPanelContainer,
  LanguagePanel
} from "./style";
import { constructUrl } from "../../utils/shopUtils";
import { languageToCode, pimLocalesDropdown } from "../../utils/languageUtils";
import { ENVIRONMENT } from "../../constants";
import { useBasenamePath } from "../../utils/useBasenamePath";
import { useIntl } from "react-intl";
import { useRaffaella } from "@eriksdigital/raffaella";

export type SalesOrganization = {
  sales_organization: string;
  shop_url: string;
  country: string;
  store_id: number;
  guided_selling_base_url: string;
};

const Header = () => {
  const { language, setLanguage, erpSystem } = useContext<any>(LanguageContext);
  const intl = useIntl();
  const [languageDropdown, updateLanguageDropdown] = useState<any>(
    Object.values(pimLocalesDropdown)
  );
  const basenamePath = useBasenamePath();
  const history = useHistory();
  const [user, makeResourceCallUser] = useRaffaella("user");
  const [availableLanguages, makeResourceCallAvailableLanguages] = useRaffaella(
    "availableLanguages"
  );
  const [salesOrganization, makeResourceCallSalesOrganisation] = useRaffaella(
    "salesOrganization"
  );
  const [isMenuOpen, toggleMenu] = useState<boolean>(false);
  const menu = useRef<any>(null);

  useEffect(() => {
    makeResourceCallUser &&
      makeResourceCallUser({
        apiKey: "user",
        reqData: {
          languageId: languageToCode(language),
          erpSystem: erpSystem.toUpperCase(),
          targetSystem: "SEL_MOT"
        }
      });
  }, [makeResourceCallUser, language, erpSystem]);

  useEffect(() => {
    makeResourceCallSalesOrganisation &&
      makeResourceCallSalesOrganisation({ apiKey: "salesOrganization" });
  }, [makeResourceCallSalesOrganisation]);

  useEffect(() => {
    if (salesOrganization.data) {
      const currentStore: SalesOrganization = salesOrganization.data.find(
        (org: SalesOrganization) => org.country === erpSystem.toUpperCase()
      );

      makeResourceCallAvailableLanguages &&
        makeResourceCallAvailableLanguages({
          apiKey: "availableLanguages",
          reqData: {
            storeUrl: currentStore.shop_url.slice(0, -1),
            storeId: currentStore.store_id
          }
        });
    }
  }, [salesOrganization, makeResourceCallAvailableLanguages, erpSystem]);

  useEffect(() => {
    if (availableLanguages?.data) {
      const newDropdown = availableLanguages.data.map(
        (lang: { countryCode: string }) => {
          return pimLocalesDropdown[lang.countryCode];
        }
      );
      updateLanguageDropdown(newDropdown);
      if (
        !newDropdown.find(
          (locale: { value: string }) => locale.value === language
        )
      ) {
        setLanguage(newDropdown[0].value);
      }
    }
  }, [language, availableLanguages, setLanguage]);

  const handleChangeLanguage = ({ target: { value } }: any) => {
    history.push(basenamePath);
    setLanguage(value as string);
  };

  const handleMenuToggle = () => {
    toggleMenu(!isMenuOpen);
  };

  const handleClickOutside = (e: Event) => {
    if (menu.current.contains(e.target)) {
      return;
    }

    toggleMenu(false);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isMenuOpen]);

  return (
    <HeaderWrapper data-testid="header">
      <HeaderInnerWrapper>
        <HeaderInner>
          <LogoWrapper
            data-testid="tco-logo"
            href={`${constructUrl(
              ENVIRONMENT as any,
              erpSystem,
              language
            )}/${intl.formatMessage({
              id: "eriks-shop.motor-category",
              defaultMessage: "gearboxes-motors-and-drives-electric-motors/",
              description: "url translated"
            })}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo size="auto" />
          </LogoWrapper>
          <div style={{ display: "flex", alignItems: "center" }}>
            {!!user.data && user.data.loggedIn && <ShoppingCart />}
            {!user.isLoading && !!user.data ? (
              <LogInStatus user={user.data} />
            ) : null}
            <Hamburger
              id="menu-button"
              onClick={handleMenuToggle}
              open={isMenuOpen}
            />
          </div>
        </HeaderInner>
        <MenuPanelContainer id="menu" ref={menu}>
          <LanguagePanel data-testid="menu-panel" menuOpen={isMenuOpen}>
            <StyledSelect
              data-testid="language-select"
              onChange={handleChangeLanguage}
              value={language}
            >
              {languageDropdown.map(
                (lang: { value: string; label: string }) => {
                  return (
                    <option
                      data-testid={`language-${lang.value}`}
                      value={lang.value}
                      key={lang.value}
                    >
                      {lang.label}
                    </option>
                  );
                }
              )}
            </StyledSelect>
          </LanguagePanel>
        </MenuPanelContainer>
      </HeaderInnerWrapper>
    </HeaderWrapper>
  );
};

export default Header;

import "react-app-polyfill/stable";
import "react-app-polyfill/ie11";
import "./polyfills";

import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { GTMProvider } from "@eriksdigital/gs-ga4";

import App from "./App";
import GlobalStyles from "./styles/GlobalStyles";

if (process.env.NODE_ENV !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React);
}

ReactDOM.render(
  <StrictMode>
    <GTMProvider env={process.env.REACT_APP_ENVIRONMENT}>
      <App />
      <GlobalStyles />
    </GTMProvider>
  </StrictMode>,
  document.getElementById("root") || document.createElement("div")
);

export const prepareQueryParams = (state) => {
  const stateClone = { ...state };
  if (stateClone.daysAYear && stateClone.hoursPerDay) {
    stateClone.annualOperationHours = (
      stateClone.daysAYear * stateClone.hoursPerDay
    ).toString();
    delete stateClone.daysAYear;
    delete stateClone.hoursPerDay;
  }
  if (stateClone.energyTariff === "") {
    delete stateClone.energyTariff;
  }
  if (stateClone.energyTariff) {
    stateClone.energyTariff = stateClone.energyTariff.replace(",", ".");
  }
  if (stateClone.repair === "false") {
    delete stateClone.rewind;
    stateClone.repairCost = "0";
  }
  delete stateClone.repair;
  return stateClone;
};

export const prepareAttributeQueryParams = (currentAttributes) => {
  const { attributes } = currentAttributes;
  const mounting = !!attributes.mounting
    ? { mounting: attributes.mounting }
    : {};
  const speed = !!attributes.speed ? { speed: attributes.speed } : {};
  const power = !!attributes.power ? { power: attributes.power } : {};
  const ratedVoltage = !!attributes.ratedVoltage
    ? { ratedVoltage: attributes.ratedVoltage }
    : {};
  return { ...mounting, ...speed, ...power, ...ratedVoltage };
};

import styled from "styled-components";

export const MainWrapper = styled.div`
  max-width: 1024px;
  width: 100%;
`;

export const MainWrapperWide = styled.div`
  width: 100%;
  max-width: 75em;
  margin: 0 auto;
  padding: 0 2rem 0;
`;
